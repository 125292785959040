<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个人信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading">
      <el-button type="primary" @click="addNotice">新增</el-button>
      <el-button type="primary" @click="refresh">刷新</el-button>
      <el-table :data="tableData" border style="width: 100%; display: inline-block;margin-top: 20px">
        <el-table-column align="center" prop="id" label="Id" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="content" label="公告内容" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态" width="auto">
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? '禁用' : '正常' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="create_time" label="创建时间" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="simple_desc" label="操作" width="auto">
          <template slot-scope="scope">
            <el-button @click="remove(scope.row.id,scope.row.status)" type="text" size="small">{{ scope.row.status === 1 ? '下架' : '上架' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>


    <el-dialog
        v-loading="loading"
        title="新增公告"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <div style="line-height: 40px">
        <div>
          公告内容：
          <el-input v-model="input" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cannel">取 消</el-button>
    <el-button type="primary" @click="confirm">确 定</el-button>
  </span>
    </el-dialog>


  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
      addUser: "",
      dialogVisible: false,
      userName: window.sessionStorage.getItem("username"),
      loading: false,
      tableData: '',
      // 当前页
      pageNum: 1,
      // 当前每页显示多少条数据
      pageSize: 10,
      total: 0
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    async confirm(){
      if(this.input === ''){
        this.$message.error("内容不能为空")
        return
      }
      console.log(this.input)
      this.loading = true
      const {data:res} = await this.$http.post('bk_add_notice',{"info":this.input})
      if(res.status === 200){
        this.loading = false
        this.$message.success("添加成功")
        this.dialogVisible = false
        this.input = ''
        this.getuser()
      }else {
        this.loading = false
        this.$message.error('添加失败，请稍微再试')
      }
    },
    remove(id, status) {
      const statusText = status === 1 ? '下架' : '上架';
      this.$confirm(`此操作将${statusText}此条信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.ChangeNoticeStatus(id,statusText)
        console.log("确定下架")
      }).catch(()=>{
        console.log('取消删除')
      })
    },
    async ChangeNoticeStatus(id,status){
      this.loading = true
      const {data:res} = await  this.$http.post('bk_change_notice_status',{"NoticeId":id,"status":status})
      if(res.status === 200){
        this.loading = false
        this.getuser()
        this.$message.success('下架成功')
      }else {
        this.loading = false
        this.$message.error("下架失败，请稍微后再试")
      }
    },
    cannel() {
      this.dialogVisible = false
      this.input = ''
    },
    addNotice(e) {
      console.log(e)
      this.addUser = e
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    refresh() {
      this.getuser()
    },
    async getuser() {
      this.loading = true
      console.log("pageNum======>", this.pageNum)
      console.log("pageSize=====>", this.pageSize)
      this.tableData = []
      const {data: res} = await this.$http.post('bk_query_notice', {
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
      }, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      } else {
        this.$message.error(res.msg)
      }

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getuser()
    },
    handleCurrentChange(newSize) {
      this.pageNum = newSize
      this.getuser()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 35px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
