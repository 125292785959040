<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个人信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading">
      <el-button type="primary" @click="addUser">新增</el-button>
      <el-button type="primary" @click="refresh">刷新</el-button>
      <el-input placeholder="请输入用户ID" prefix-icon="el-icon-search" v-model="Searchinput" style="width: 20%;margin-left: 20px" @blur="SearchBtn">
      </el-input>
      <el-table :data="tableData" border style="width: 100%; display: inline-block;margin-top: 20px" :default-sort="{prop: 'studentNum'}">
        <el-table-column align="center" prop="userId" label="用户Id" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="userName" label="昵称" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="gender" label="性别" width="auto">
          <template slot-scope="scope">
            {{ scope.row.gender === 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="image" label="头像" width="auto">
          <template slot-scope="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.image"
                :preview-src-list="[scope.row.image]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="city" label="城市" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="birthday" label="生日" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="phone" label="手机号" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="QQ" label="QQ" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="WX" label="WX" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="other" label="其他联系方式" width="auto">
        </el-table-column>
                <el-table-column align="center" prop="personal_note" label="个人说明" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="is_up" label="置顶" width="auto">
          <template slot-scope="scope">
            {{ scope.row.is_up === 0 ? '不置顶' : '置顶' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="show_status" label="星榜展示" width="auto">
          <template slot-scope="scope">
            {{ scope.row.show_status === 0 ? '不展示' : '展示' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态" width="auto">
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? '禁用' : '正常' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="create_time" label="注册时间" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="update_time" label="更新时间" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="simple_desc" label="操作" width="auto">
          <template slot-scope="scope">
            <el-button @click="editClick(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="rePasswordClick(scope.row)" type="text" size="small">重置密码</el-button>
            <!--            <el-button @click="handleClick(scope.row.id,scope.row.status)" type="text" size="small">{{ scope.row.status === 0 ? '禁用' : '启用' }}</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>

    <el-dialog title="编辑用户信息" :visible.sync="dialogVisible" width="30%" :show-close="false">
      <el-form ref="form" :model="tableInfo" label-width="80px" v-loading="loading">
        <el-form-item label="用户昵称">
          <el-input v-model="tableInfo.userName"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="tableInfo.gender">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="用户头像">
          <el-upload
              class="avatar-uploader"
              action="#"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="edithandleFileChange">
            <img v-if="tableInfo.image" :src="tableInfo.image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="城市">
          <el-select v-model="tableInfo.city" placeholder="请选择地区">
            <el-option v-for="option in city" :key="option.id" :label="option.district" :value="option.district"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="QQ">
          <el-input type="QQ" v-model="tableInfo.QQ"></el-input>
        </el-form-item>
        <el-form-item label="WX">
          <el-input type="WX" v-model="tableInfo.WX"></el-input>
        </el-form-item>
        <el-form-item label="其他联系">
          <el-input type="other" v-model="tableInfo.other"></el-input>
        </el-form-item>
        <el-form-item label="相册中心">
          <el-upload
              class="upload-demo"
              action="#"
              :auto-upload="false"
              :on-change="edithandleFileListChange"
              :on-remove="edithandleUserPhotoRemove"
              :file-list="edituserPhoto"
              list-type="picture"
              :limit="9">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>

        </el-form-item>
        <el-form-item label="置顶">
          <el-radio-group v-model="tableInfo.is_up">
            <el-radio :label="0">不置顶</el-radio>
            <el-radio :label="1">置顶</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="星榜展示">
          <el-radio-group v-model="tableInfo.show_status">
            <el-radio :label="0">不展示</el-radio>
            <el-radio :label="1">展示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
	    <el-button @click="UpdateCannel">取 消</el-button>
	    <el-button type="primary" @click="UpdateSubmit">确 定</el-button>
	  </span>
    </el-dialog>

    <el-dialog title="新增用户" :visible.sync="addDialogVisible" width="30%">
      <el-form ref="form" :model="addUserInfo" label-width="80px" v-loading="loading">
        <el-form-item label="用户昵称">
          <el-input v-model="addUserInfo.userName"></el-input>
        </el-form-item>
        <el-form-item label="用户头像">
          <el-upload
              class="avatar-uploader"
              action="#"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleFileChange">
            <img v-if="addUserInfo.image.url" :src="addUserInfo.image.url" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="addUserInfo.gender">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="城市">
          <el-select v-model="addUserInfo.city" placeholder="请选择地区">
            <el-option v-for="option in city" :key="option.id" :label="option.district" :value="option.district"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="QQ">
          <el-input type="QQ" v-model="addUserInfo.QQ"></el-input>
        </el-form-item>
        <el-form-item label="WX">
          <el-input type="WX" v-model="addUserInfo.WX"></el-input>
        </el-form-item>
        <el-form-item label="other">
          <el-input type="other" v-model="addUserInfo.other"></el-input>
        </el-form-item>
        <el-form-item label="相册中心">
          <el-upload
              class="upload-demo"
              action="#"
              :auto-upload="false"
              :on-change="handleFileListChange"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
              :limit="9">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="置顶">
          <el-radio-group v-model="addUserInfo.is_up">
            <el-radio label="0">不置顶</el-radio>
            <el-radio label="1">置顶</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="星榜展示">
          <el-radio-group v-model="addUserInfo.show_status">
            <el-radio label="0">不展示</el-radio>
            <el-radio label="1">展示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
	    <el-button @click="addDialogVisible = false">取 消</el-button>
	    <el-button type="primary" @click="addSubmit">确 定</el-button>
	  </span>
    </el-dialog>


  </div>
</template>

<script>
export default {
  data() {
    return {
      Searchinput: "",
      fileList: [],
      imageUrl: '',
      addUserInfo: {
        "userName": "",
        "gender": "",
        "city": "",
        "is_up": "",
        "birthday": "",
        "show_status": "",
        "image": {
          "name": "",
          "url": ""
        },
        "QQ": "",
        "WX": "",
        "other": ""
      },
      userName: window.sessionStorage.getItem("username"),
      formInline: {
        studentName: '',
        studentNum: ''
      },
      dialogDelete: false,
      loading: false,
      dialogVisible: false,
      tableData: '',
      addDialogVisible: false,
      // 当前页
      pageNum: 1,
      // 当前每页显示多少条数据
      pageSize: 10,
      total: 0,
      tableInfo: '',
      city: "",
      userPhoto: [],
      edituserPhoto: []
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    async rePasswordClick(e) {
      this.loading = true
      const {data: res} = await this.$http.post('bk_reset_password', {"userId": e.userId})
      if (res.status === 200) {
        this.loading = false
        this.$message.success("密码重置成功")
      } else {
        this.loading = false
        this.$message.error("密码重置失败，请联系管理员")
      }
    },
    async SearchBtn() {
      console.log(this.Searchinput)
      if (!this.Searchinput) {
        this.Searchinput = ''
      }
      const {data: res} = await this.$http.post('bk_search_user', {"search_info": this.Searchinput})
      if (res.status === 200) {
        this.tableData = res.data
        this.total = res.total
      }
    },
    edithandleFileListChange(file) {
      const validFormats = ['image/jpeg', 'image/png', 'image/jpg'];
      console.log(file.raw.type);
      if (!validFormats.includes(file.raw.type)) {
        this.edituserPhoto = []; // 如果格式不对，清空文件列表
        this.$message.error('只能上传jpg/png文件');
      } else if (this.edituserPhoto.length >= 9) {
        this.$message.warning('最多只能上传9张图');
      } else {
        let filedict = {};
        const reader = new FileReader();
        reader.onload = (e) => {
          filedict.url = e.target.result;
          filedict.name = file.name; // file.name is provided directly by element-ui component
          this.edituserPhoto.push(filedict); // 添加文件到数组
          console.log("fileList", this.edituserPhoto); // 检查文件列表
        };
        reader.readAsDataURL(file.raw); // 读取文件
      }
    },
    handleFileListChange(file) {
      // 检查文件格式
      const validFormats = ['image/jpeg', 'image/png', 'image/jpg'];
      console.log(file.raw.type)
      if (!validFormats.includes(file.raw.type)) {
        this.fileList = []
        this.$message.error('只能上传jpg/png文件');
      } else if (this.fileList.length > 9) {
        this.$message.warning('最多只能上传9张图');
      } else {
        let filedict = {};
        const reader = new FileReader();
        reader.onload = (e) => {
          filedict.url = e.target.result;
          filedict.name = file.name; // file.name is provided directly by element-ui component
          this.fileList.push(filedict);
          console.log("fileList", this.fileList); // Check the fileList array content in the console
        };
        reader.readAsDataURL(file.raw); // Assuming 'file.raw' contains the file data
      }
    },
    async addSubmit() {
      this.loading = true
      if (this.addUserInfo.userName === "") {
        this.loading = false
        return this.$message.error("昵称不能为空")
      }
      if (this.addUserInfo.image.url === "") {
        this.loading = false
        return this.$message.error("头像不能为空")
      }
      if (this.addUserInfo.gender === "") {
        this.loading = false
        return this.$message.error("性别不能为空")
      }
      const {data: res} = await this.$http.post('add_user', {"userInfo": this.addUserInfo, "fileList": this.fileList})
      if (res.status === 200) {
        this.loading = false
        this.addDialogVisible = false
        this.fileList = []
        this.addUserInfo = {
          "userName": "",
          "gender": "",
          "city": "",
          "is_up": "",
          "birthday": "",
          "show_status": "",
          "image": {
            "name": "",
            "url": ""
          },
          "QQ": "",
          "WX":"",
          "other":""
        }
        return this.$message.success("新增成功")
      }
    },
    edithandleFileChange(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        console.log("handleFileChange", e)
        this.tableInfo.image = e.target.result
      }
      reader.readAsDataURL(file.raw)
    },
    handleFileChange(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        console.log("handleFileChange", e)
        this.addUserInfo.image.url = e.target.result
        this.addUserInfo.image.name = file.name
      }
      reader.readAsDataURL(file.raw)
    },
    UpdateCannel() {
      this.dialogVisible = false
      this.userPhoto = []
      this.edituserPhoto = []
      this.tableInfo = ''
    },
    reset() {
      // 当前页
      this.pageNum = 1
      // 当前每页显示多少条数据
      this.pageSize = 10
      this.loading = true
      this.$refs.searchInput.resetFields()
      this.getuser()
    },
    async forbidden(id, status) {
      const {data: res} = await this.$http.post('bk_disable', {'id': id, 'status': status}, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.getuser()
        return this.$message.success('禁用成功')
      }
    },
    async addUser() {
      this.addDialogVisible = true
      const {data: res} = await this.$http.post('query_city')
      this.city = res.data
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    edithandleUserPhotoRemove(file, fileList) {
      this.edituserPhoto = fileList;
    },
    handleUserPhotoRemove(file, fileList) {
      this.userPhoto = fileList;
    },
    refresh() {
      this.getuser()
    },
    async UpdateSubmit() {
      this.loading = true
      console.log(this.tableInfo)
      console.log(this.userPhoto)
      if (!this.tableInfo.gender) {
        return this.$message.error("性别不能为空")
      }
      const {data: res} = await this.$http.post('bk_update_user_info', {"userDate": this.tableInfo, "userPhoto": this.edituserPhoto})
      if (res.status === 200) {
        this.loading = false
        this.dialogVisible = false
        this.getuser()
        this.userPhoto = []
        this.edituserPhoto = []
        return this.$message.success("编辑成功")
      } else {
        this.loading = false
        return this.$message.error("编辑失败，请联系管理员")
      }

    },
    async editClick(event) {
      console.log(event)
      this.tableInfo = JSON.parse(JSON.stringify(event));
      console.log("tableInfo", this.tableInfo)
      this.dialogVisible = true
      const {data: res} = await this.$http.post('query_city')
      const {data: rep} = await this.$http.post('bk_query_user_photo', {"userId": event.userId})
      this.city = res.data
      this.edituserPhoto = rep.data
      console.log(this.userPhoto)
      console.log(this.edituserPhoto)
    },
    async handleClick(id, status) {
      console.log(id, status)
      const statusText = status === 0 ? '禁用' : '启用';
      this.$confirm(`此操作将${statusText}此账号, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.forbidden(id, status)
        console.log("确定删除")
      }).catch(() => {
        console.log("取消删除")
      })
    },
    async getuser() {
      this.loading = true
      console.log("pageNum======>", this.pageNum)
      console.log("pageSize=====>", this.pageSize)
      this.tableData = []
      const {data: res} = await this.$http.post('bk_user', {
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
      }, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      } else {
        this.$message.error(res.msg)
      }

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getuser()
    },
    handleCurrentChange(newSize) {
      this.pageNum = newSize
      this.getuser()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 35px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
