<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>广场信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading">
      <el-button type="primary" @click="addSquare">新增</el-button>
      <el-button type="primary" @click="reset">刷新</el-button>
      <el-table :data="tableData" border style="width: 100%; display: inline-block;margin-top: 20px" :default-sort="{prop: 'studentNum'}">
        <el-table-column align="center" prop="userId" label="用户Id" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="userName" label="用户昵称" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="image_url" label="图片" width="auto">
          <template slot-scope="scope">
            <template>
              <div style="display: flex;gap: 10px">
                <div v-for="(index, key) in scope.row.square_image" :key="key">
                  <el-image
                      style="width: 50px; height: 50px"
                      :src="index.url"
                      :preview-src-list="[index.url]">
                  </el-image>
                </div>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="square_text" label="内容" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="like_number" label="点赞次数" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="share_number" label="分享次数" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="create_time" label="发布时间" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态" width="auto">
          <template slot-scope="scope">
            {{ scope.row.status === 1 ? '上架' : '下架' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="simple_desc" label="操作" width="auto">
          <template slot-scope="scope">
            <el-button @click="editClick(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="handleClick(scope.row.id,scope.row.status)" type="text" size="small">{{ scope.row.status === 1 ? '下架' : '上架' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>

    <el-dialog
        title="新增广场"
        :visible.sync="dialogVisible"
        width="30%">
      <el-form width="30%" v-loading="loading">
        <el-form-item label="发布ID">
          <el-select v-model="squareInfo.userId" placeholder="请选择发布者的用户ID">
            <el-option v-for="option in userId" :key="option.userId" :label="option.userId" :value="option.userId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相册">
          <el-upload
              class="upload-demo"
              action="#"
              :auto-upload="false"
              :on-change="handleFileListChange"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
              :limit="9">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="发布内容">
          <el-input type="textarea" v-model="squareInfo.text"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
    </el-dialog>


    <el-dialog
        title="编辑信息"
        :visible.sync="editdialogVisible"
        width="40%">
      <el-form :model="editinfo" label-width="80px" v-loading="loading">
        <el-form-item label="用户ID">
          <el-input :disabled="true" style="width: 40%" v-model="editinfo.userId"></el-input>
        </el-form-item>
        <el-form-item label="相册">
          <el-upload
              class="upload-demo"
              action="#"
              :auto-upload="false"
              :on-change="edithandleFileListChange"
              :on-remove="edithandleRemove"
              :file-list="editfileList"
              list-type="picture"
              :limit="9">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="发布内容">
          <el-input type="textarea" v-model="editinfo.square_text"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editdialogVisiblecannel">取 消</el-button>
    <el-button type="primary" @click="editdialogVisiblesubmit">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      editinfo: "",
      editfileList: [],
      editdialogVisible: false,
      fileList: [],
      squareInfo: {
        userId: "",
        text: ""
      },
      disabled: false,
      userName: window.sessionStorage.getItem("username"),
      loading: false,
      dialogVisible: false,
      tableData: '',
      // 当前页
      pageNum: 1,
      // 当前每页显示多少条数据
      pageSize: 10,
      total: 0,
      userId: ""
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    async editdialogVisiblesubmit() {
      this.loading = true
      console.log(this.editinfo)
      console.log(this.editfileList)
      const {data: res} = await this.$http.post('bk_update_square', {"id": this.editinfo.id, "square_image": this.editfileList, "square_text": this.editinfo.square_text})
      if (res.status === 200) {
        this.loading = false
        this.editinfo = ''
        this.editfileList = []
        this.editdialogVisible = false
        this.getuser()
        return this.$message.success("更新成功")
      } else {
        this.loading = false
        return this.$message.error("更新失败，请联系管理员")
      }
    },
    editdialogVisiblecannel() {
      this.editinfo = {}
      this.editdialogVisible = false
      this.editfileList = []
    },
    editClick(e) {
      console.log(e)
      this.editdialogVisible = true
      this.editinfo = JSON.parse(JSON.stringify(e))
      if (e.square_image) {
        this.editfileList = JSON.parse(JSON.stringify(e.square_image))
      } else {
        this.editfileList = []
      }

    },
    async submit() {
      this.loading = true
      const {data: res} = await this.$http.post('bk_add_square', {"userId": this.squareInfo.userId, "text": this.squareInfo.text, "Image": this.fileList})
      if (res.status === 200) {
        this.loading = false
        this.dialogVisible = false
        this.squareInfo.text = ''
        this.squareInfo.userId = ''
        this.fileList = []
        return this.$message.success("上传成功")
      } else {
        this.loading = false
        return this.$message.error("上传失败，请联系管理员")
      }
    },
    edithandleFileListChange(file) {
      // 检查文件格式
      const validFormats = ['image/jpeg', 'image/png', 'image/jpg'];
      console.log(file.raw.type)
      if (!validFormats.includes(file.raw.type)) {
        this.editfileList = []
        this.$message.error('只能上传jpg/png文件');
      } else if (this.editfileList.length > 9) {
        this.$message.warning('最多只能上传9张图');
      } else {
        let filedict = {};
        const reader = new FileReader();
        reader.onload = (e) => {
          filedict.url = e.target.result;
          filedict.name = file.name; // file.name is provided directly by element-ui component
          this.editfileList.push(filedict);
          console.log("editinfo", this.editfileList); // Check the fileList array content in the console
        };

        reader.readAsDataURL(file.raw); // Assuming 'file.raw' contains the file data
      }
    },
    handleFileListChange(file) {
      // 检查文件格式
      const validFormats = ['image/jpeg', 'image/png', 'image/jpg'];
      console.log(file.raw.type)
      if (!validFormats.includes(file.raw.type)) {
        this.fileList = []
        this.$message.error('只能上传jpg/png文件');
      } else if (this.fileList.length > 9) {
        this.$message.warning('最多只能上传9张图');
      } else {
        let filedict = {};
        const reader = new FileReader();
        reader.onload = (e) => {
          filedict.url = e.target.result;
          filedict.name = file.name; // file.name is provided directly by element-ui component
          this.fileList.push(filedict);
          console.log("fileList", this.fileList); // Check the fileList array content in the console
        };

        reader.readAsDataURL(file.raw); // Assuming 'file.raw' contains the file data
      }
    },
    edithandleRemove(file, fileList) {
      this.editfileList = fileList;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    async addSquare() {
      this.dialogVisible = true
      const {data: res} = await this.$http.post('bk_query_userId')
      this.userId = res.data
    },
    reset() {
      // 当前页
      this.pageNum = 1
      // 当前每页显示多少条数据
      this.pageSize = 10
      this.loading = true
      this.getuser()
    },
    async forbidden(id, status) {
      const {data: res} = await this.$http.post('bk_disable_square', {'id': id, 'status': status}, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.getuser()
        return this.$message.success('禁用成功')
      }
    },
    async handleClick(id, status) {
      console.log(id, status)
      const statusText = status === 1 ? '下架' : '上架';
      this.$confirm(`此操作将${statusText}此条信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.forbidden(id, status)
        console.log("确定删除")
      }).catch(() => {
        console.log("取消删除")
      })
    },
    async getuser() {
      this.loading = true
      console.log("pageNum======>", this.pageNum)
      console.log("pageSize=====>", this.pageSize)
      this.tableData = []
      const {data: res} = await this.$http.post('bk_query_square', {
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
      }, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      } else {
        this.$message.error(res.msg)
      }

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getuser()
    },
    handleCurrentChange(newSize) {
      this.pageNum = newSize
      this.getuser()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 35px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
