import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from 'axios'
import './assets/css/global.css'


Vue.config.productionTip = false
Vue.prototype.$http = axios
// axios.defaults.baseURL = 'https://www.zl210.cn/api/v1'
axios.defaults.baseURL = 'http://www.justicezzh.cn:8080/api/v1'
// axios.defaults.baseURL = 'http://10.80.12.49:5009/api/v1'
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})
axios.defaults.timeout = 6000
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
